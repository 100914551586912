import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _initialPlotConfigSta;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { plotTypes } from 'utils/constants';
import { legendBaseState, dimensionsBaseState, axesBaseState, axesRangesBaseState, titleBaseState, fontStyleBaseState, colourBaseState, markerBaseState, labelBaseState } from './baseStylesState';

// PLOTS & TABLES - Categorical Embedding
var embeddingCategoricalInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 0
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: _objectSpread(_objectSpread({}, labelBaseState), {}, {
    enabled: false
  }),
  selectedCellSet: 'louvain',
  selectedSample: 'All'
};

// PLOTS & TABLES - Continuous Embedding
var embeddingContinuousInitialConfig = {
  spec: '1.0.0',
  legend: legendBaseState,
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  logEquation: 'datum.expression*1',
  shownGene: null,
  expressionValue: 'raw',
  truncatedValues: true,
  selectedSample: 'All',
  keepValuesOnReset: ['shownGene']
};

// PLOTS & TABLES - Heatmap
var heatmapInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    show: true,
    position: 'horizontal'
  }),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 500,
    height: 500
  }),
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20,
    dx: 0
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  // When null, this indicates that the value is its initial one
  // and the marker genes should be loaded
  selectedGenes: null,
  selectedCellSet: 'louvain',
  selectedPoints: 'All',
  labelColour: 'transparent',
  selectedTracks: ['louvain'],
  groupedTracks: ['sample', 'louvain'],
  expressionValue: 'raw',
  truncatedValues: true
};

// PLOTS & TABLES - Marker heatmap
var markerHeatmapInitialConfig = _objectSpread(_objectSpread({}, heatmapInitialConfig), {}, {
  useMarkerGenes: false,
  guardLines: false,
  nMarkerGenes: 5,
  showGeneLabels: true,
  keepValuesOnReset: ['selectedGenes']
});

// PLOTS & TABLES - Volcano plot
var volcanoInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    position: 'top-right'
  }),
  dimensions: dimensionsBaseState,
  marker: _objectSpread(_objectSpread({}, markerBaseState), {}, {
    showOpacity: false,
    size: 32
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'log fold change',
    yAxisText: '-log10(adj p-value)',
    gridOpacity: 5,
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: titleBaseState,
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  labels: labelBaseState,
  noDifferenceColor: '#aaaaaa',
  significantUpregulatedColor: '#0000ffaa',
  significantDownregulatedColor: '#ff0000',
  xAxisAuto: true,
  yAxisAuto: true,
  logFoldChangeDomain: 1,
  maxNegativeLogpValueDomain: 50,
  adjPvalueThreshold: 0.05,
  logFoldChangeThreshold: 1,
  logFoldChangeTickCount: 5,
  negativeLogpValueTickCount: 5,
  downsampleRatio: 0,
  showLogFoldChangeThresholdGuides: true,
  showpvalueThresholdGuides: true,
  thresholdGuideWidth: 1,
  logFoldChangeThresholdColor: '#ff0000',
  pvalueThresholdColor: '#ff0000',
  textThresholdValue: 240,
  strokeOpa: 1,
  strokeCol: '#000000'
};

// PLOTS & TABLES - Frequency
var frequencyInitialConfig = {
  spec: '1.0.0',
  frequencyType: 'proportional',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    title: 'Cell Set',
    offset: 40
  }),
  labels: labelBaseState,
  dimensions: dimensionsBaseState,
  marker: markerBaseState,
  colour: colourBaseState,
  title: titleBaseState,
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Samples',
    yAxisText: 'Proportion',
    xAxisRotateLabels: true,
    offset: 10
  }),
  axesRanges: {
    yAxisAuto: true,
    yMin: 0,
    yMax: 10
  },
  fontStyle: fontStyleBaseState,
  proportionGrouping: 'louvain',
  xAxisGrouping: 'sample',
  axisTitlesize: 13,
  geneexpLegendloc: ''
};

// PLOTS & TABLES - Violin
var violinConfig = {
  spec: '1.0.0',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    enabled: false
  }),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    offset: 10,
    xAxisRotateLabels: true
  }),
  axesRanges: {
    yAxisAuto: true,
    yMin: 0,
    yMax: 10
  },
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  shownGene: null,
  selectedCellSet: 'louvain',
  selectedPoints: 'All',
  selectedPointsVisible: true,
  statisticsVisible: false,
  kdeBandwidth: 0.3,
  normalised: 'zScore',
  keepValuesOnReset: ['shownGene', 'title']
};

// PLOTS & TABLES - Dot Plot
var dotPlotConfig = {
  spec: '1.0.0',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    enabled: true,
    direction: 'vertical'
  }),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    tickOffset: 10,
    xAxisRotateLabels: true,
    xAxisText: 'Gene names',
    yAxisText: 'Louvain clusters'
  }),
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  useAbsoluteScale: true,
  useMarkerGenes: false,
  nMarkerGenes: 3,
  selectedGenes: [],
  selectedCellSet: 'louvain',
  selectedPoints: 'All',
  keepValuesOnReset: ['selectedGenes']
};

// PLOTS & TABLES - Trajectory Analysis
var trajectoryAnalysisInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y']
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: _objectSpread(_objectSpread({}, labelBaseState), {}, {
    enabled: false
  }),
  selectedNodes: [],
  selectedCellSets: ['louvain']
};

// PLOTS & TABLES - Multi view
var multiViewInitialConfig = {
  ncols: 1,
  nrows: 1,
  plotUuids: []
};

// EMBEDDING PREVIEW - Coloured by sample
var embeddingPreviewBySampleInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: _objectSpread(_objectSpread({}, labelBaseState), {}, {
    enabled: false
  }),
  selectedCellSet: 'louvain',
  selectedSample: 'All'
};

// EMBEDDING PREVIEW - Coloured by cell sets
var embeddingPreviewByCellSetsInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: _objectSpread(_objectSpread({}, labelBaseState), {}, {
    enabled: false
  }),
  selectedCellSet: 'louvain',
  selectedSample: 'All'
};

// EMBEDDING PREVIEW - Config for fraction of mitochondrial reads
var embeddingPreviewMitochondrialContentInitialConfig = {
  spec: '1.0.0',
  legend: legendBaseState,
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  shownGene: null,
  selectedSample: 'All'
};

// EMBEDDING PREVIEW - Config for doublet score
var embeddingPreviewDoubletScoreInitialConfig = {
  spec: '1.0.0',
  legend: legendBaseState,
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  selectedSample: 'All'
};
var embeddingPreviewNumOfGenesInitialConfig = {
  spec: '1.0.0',
  legend: legendBaseState,
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  selectedSample: 'All'
};
var embeddingPreviewNumOfUmisInitialConfig = {
  spec: '1.0.0',
  legend: legendBaseState,
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: null,
    yAxisText: null,
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    dx: 0,
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  selectedSample: 'All'
};
var interactiveHeatmapInitialConfig = {
  selectedCellSet: 'louvain',
  selectedPoints: 'All',
  selectedTracks: ['louvain'],
  groupedTracks: ['louvain', 'sample'],
  expressionValue: 'raw',
  legendIsVisible: true
};

// CELL SIZE DISTRIBUTION - Cell Size Distribution Histogram
var cellSizeDistributionHistogram = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 530,
    height: 400
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: '#Transcripts in cell',
    yAxisText: '#Transcripts * #Cells'
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  minCellSize: 10800
};

// CELL SIZE DISTRIBUTION - Cell Size Distribution Knee Plot
var cellSizeDistributionKneePlot = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 530,
    height: 400
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Barcode rank (log scale)',
    yAxisText: 'Transcripts (log scale)'
  }),
  axesRanges: _objectSpread(_objectSpread({}, axesRangesBaseState), {}, {
    xMin: 1,
    yMin: 1
  }),
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  minCellSize: 990
};

// MITOCHONDRIAL CONTENT - Mitochondrial Fraction Histogram
var mitochondrialFractionHistogram = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 530,
    height: 400
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Percentage of mitochondrial reads',
    yAxisText: 'Percentage of cells'
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20,
    dx: 0
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  maxFraction: 0.1
};

// MITOCHONDRIAL CONTENT - Mitochondrial Fraction Log Histogram
var mitochondrialFractionLogHistogram = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 530,
    height: 400
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Percentage of mitochondrial reads',
    yAxisText: '#Transcripts in cell'
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20,
    dx: 0
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  maxFraction: 0.2
};

// CLASSIFIER - Classifier Knee Plot
var classifierKneePlot = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 530,
    height: 400
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Barcode rank (log scale)',
    yAxisText: 'Transcripts (log scale)'
  }),
  axesRanges: _objectSpread(_objectSpread({}, axesRangesBaseState), {}, {
    xMin: 1,
    yMin: 1
  }),
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  minCellSize: 990
};

// CLASSIFIER - Classifier Empty Drops
var classifierEmptyDropsPlot = {
  spec: '1.0.0',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    enabled: false
  }),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 630,
    height: 500
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'log10[ cell size (transcripts) ]',
    yAxisText: 'FDR (emptyDrops)',
    gridOpacity: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  minProbability: 0.82,
  bandwidth: -1
};

// GENES VS UMIS - Features vs UMIs
var featuresVsUMIsScatterplot = {
  spec: '1.0.0',
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 630,
    height: 500
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'log10 [transcript counts]',
    yAxisText: 'log10 [gene counts]',
    gridOpacity: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  labels: labelBaseState,
  lower_cutoff: 4.8,
  upper_cutoff: 2.1
};

// DOUBLE FILTER - Doublet filter histogram
var doubletScoreHistogram = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 530,
    height: 400
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Probability of being a doublet',
    yAxisText: 'Frequency'
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: labelBaseState,
  probThreshold: 0.2
};

// DATA INTEGRATION - Embedding by Samples
var dataIntegrationEmbeddingInitialConfig = {
  spec: '1.0.0',
  legend: _objectSpread({}, legendBaseState),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: '',
    yAxisText: '',
    defaultValues: ['x', 'y'],
    offset: 10
  }),
  axesRanges: axesRangesBaseState,
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    fontSize: 20
  }),
  fontStyle: fontStyleBaseState,
  colour: colourBaseState,
  marker: markerBaseState,
  labels: _objectSpread(_objectSpread({}, labelBaseState), {}, {
    enabled: false
  }),
  selectedCellSet: 'louvain',
  selectedSample: 'All'
};

// DATA INTEGRATION - Frequency
var dataIntegrationFrequencyInitialConfig = _objectSpread(_objectSpread({}, frequencyInitialConfig), {}, {
  xAxisGrouping: 'louvain',
  proportionGrouping: 'sample',
  legend: _objectSpread(_objectSpread({}, legendBaseState), {}, {
    title: 'Sample',
    offset: 10
  }),
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Louvain clusters',
    yAxisText: 'Proportion',
    xAxisRotateLabels: true,
    offset: 10
  }),
  axesRanges: {
    yAxisAuto: true,
    yMin: 0,
    yMax: 10
  },
  fontStyle: fontStyleBaseState
});

// DATA INTEGRATION - Elbow
var dataIntegrationElbowPlotInitialConfig = {
  legend: _objectSpread({}, legendBaseState),
  labels: labelBaseState,
  dimensions: _objectSpread(_objectSpread({}, dimensionsBaseState), {}, {
    width: 700,
    height: 550
  }),
  marker: _objectSpread({}, markerBaseState),
  fontStyle: fontStyleBaseState,
  axes: _objectSpread(_objectSpread({}, axesBaseState), {}, {
    xAxisText: 'Principal Components',
    yAxisText: 'Proportion of Variance Explained',
    titleFont: 'sans-serif',
    labelFont: 'sans-serif',
    labelFontSize: 13,
    offset: 0,
    gridOpacity: 10
  }),
  axesRanges: axesRangesBaseState,
  colour: {
    toggleInvert: '#FFFFFF'
  },
  title: _objectSpread(_objectSpread({}, titleBaseState), {}, {
    font: 'sans-serif',
    fontSize: 12
  }),
  signals: [{
    name: 'interpolate',
    value: 'linear',
    bind: {
      input: 'select',
      options: ['basis', 'cardinal', 'catmull-rom', 'linear', 'monotone', 'natural', 'step', 'step-after', 'step-before']
    }
  }]
};
var normalizedMatrixPlotConfig = {
  sample: [],
  louvain: [],
  metadata: [],
  scratchpad: []
};
var initialPlotConfigStates = (_initialPlotConfigSta = {
  cellSizeDistributionHistogram: cellSizeDistributionHistogram,
  cellSizeDistributionKneePlot: cellSizeDistributionKneePlot,
  mitochondrialFractionHistogram: mitochondrialFractionHistogram,
  mitochondrialFractionLogHistogram: mitochondrialFractionLogHistogram,
  classifierKneePlot: classifierKneePlot,
  classifierEmptyDropsPlot: classifierEmptyDropsPlot,
  featuresVsUMIsScatterplot: featuresVsUMIsScatterplot,
  doubletScoreHistogram: doubletScoreHistogram,
  embeddingCategorical: embeddingCategoricalInitialConfig,
  embeddingContinuous: embeddingContinuousInitialConfig,
  heatmap: heatmapInitialConfig,
  volcano: volcanoInitialConfig,
  markerHeatmap: markerHeatmapInitialConfig,
  violin: violinConfig
}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_initialPlotConfigSta, plotTypes.NORMALIZED_EXPRESSION_MATRIX, normalizedMatrixPlotConfig), plotTypes.DOT_PLOT, dotPlotConfig), plotTypes.TRAJECTORY_ANALYSIS, trajectoryAnalysisInitialConfig), "frequency", frequencyInitialConfig), "embeddingPreviewBySample", embeddingPreviewBySampleInitialConfig), "embeddingPreviewByCellSets", embeddingPreviewByCellSetsInitialConfig), "embeddingPreviewMitochondrialContent", embeddingPreviewMitochondrialContentInitialConfig), "embeddingPreviewDoubletScore", embeddingPreviewDoubletScoreInitialConfig), "embeddingPreviewNumOfGenes", embeddingPreviewNumOfGenesInitialConfig), "embeddingPreviewNumOfUmis", embeddingPreviewNumOfUmisInitialConfig), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_initialPlotConfigSta, "dataIntegrationEmbedding", dataIntegrationEmbeddingInitialConfig), "dataIntegrationFrequency", dataIntegrationFrequencyInitialConfig), "dataIntegrationElbow", dataIntegrationElbowPlotInitialConfig), "multiView", multiViewInitialConfig));
var initialComponentConfigStates = {
  interactiveHeatmap: interactiveHeatmapInitialConfig
};
var initialPlotDataState = {
  plotData: [],
  loading: false,
  error: false
};
export { initialPlotConfigStates, initialComponentConfigStates, initialPlotDataState };
var initialState = {};
export default initialState;