import _set from "lodash/set";
/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from '../initialState';
var updateSampleProcessingSettingsFromQC = produce(function (draft, action) {
  var _action$payload = action.payload,
    step = _action$payload.step,
    sampleId = _action$payload.sampleId,
    newSettings = _action$payload.newSettings;
  _set(draft, "processing.".concat(step, ".").concat(sampleId), newSettings);
  _set(draft, "originalProcessing.".concat(step, ".").concat(sampleId), newSettings);
}, initialState);
export default updateSampleProcessingSettingsFromQC;