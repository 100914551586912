import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getSamples from './getSamples';

// unused param is used to pass the experimentId to the input selector
// eslint-disable-next-line no-unused-vars
var getMetadataToSampleIds = function getMetadataToSampleIds(experimentId) {
  return function (samples) {
    var metadataToSampleIds = {};
    Object.entries(samples).forEach(function (_ref) {
      var _Object$entries;
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        entry = _ref2[1];
      if (key === 'meta') {
        return;
      }
      (_Object$entries = Object.entries(entry.metadata)) === null || _Object$entries === void 0 || _Object$entries.forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          metadataKey = _ref4[0],
          metadataValue = _ref4[1];
        var sampleIds = metadataToSampleIds[metadataKey];
        if (!sampleIds) {
          sampleIds = {};
          metadataToSampleIds[metadataKey] = sampleIds;
        }
        if (!sampleIds[metadataValue]) {
          sampleIds[metadataValue] = [];
        }
        sampleIds[metadataValue].push(key);
      });
    });
    return metadataToSampleIds;
  };
};
export default createMemoizedSelector(getMetadataToSampleIds, {
  inputSelectors: [{
    func: getSamples,
    paramsIngest: function paramsIngest(experimentId) {
      return [experimentId];
    }
  }]
});