/* eslint-disable import/prefer-default-export */

// Accumulates all the different selectors

import * as backendSelectors from './selectors/backendStatus';
import * as cellSetsSelectors from './selectors/cellSets';
import * as genesSelectors from './selectors/genes';
import * as componentConfigSelectors from './selectors/componentConfig';
import * as secondaryAnalysesSelectors from './selectors/secondaryAnalyses';
import * as experimentSettingsSelectors from './selectors/experimentSettings';
import * as samplesSelectors from './selectors/samples';
var getBackendStatus = function getBackendStatus() {
  for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
    params[_key] = arguments[_key];
  }
  return function (state) {
    return backendSelectors.getBackendStatus.apply(backendSelectors, params)(state.backendStatus);
  };
};
var getCellSets = function getCellSets() {
  for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    params[_key2] = arguments[_key2];
  }
  return function (state) {
    return cellSetsSelectors.getCellSets.apply(cellSetsSelectors, params)(state.cellSets);
  };
};
var getCellSetsHierarchy = function getCellSetsHierarchy() {
  for (var _len3 = arguments.length, params = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    params[_key3] = arguments[_key3];
  }
  return function (state) {
    return cellSetsSelectors.getCellSetsHierarchy.apply(cellSetsSelectors, params)(state.cellSets);
  };
};
var getCellSetsHierarchyByType = function getCellSetsHierarchyByType() {
  for (var _len4 = arguments.length, params = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    params[_key4] = arguments[_key4];
  }
  return function (state) {
    return cellSetsSelectors.getCellSetsHierarchyByType.apply(cellSetsSelectors, params)(state.cellSets);
  };
};
var getCellSetsHierarchyByKeys = function getCellSetsHierarchyByKeys() {
  for (var _len5 = arguments.length, params = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
    params[_key5] = arguments[_key5];
  }
  return function (state) {
    return cellSetsSelectors.getCellSetsHierarchyByKeys.apply(cellSetsSelectors, params)(state.cellSets);
  };
};
var getPlotConfigs = function getPlotConfigs() {
  for (var _len6 = arguments.length, params = new Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
    params[_key6] = arguments[_key6];
  }
  return function (state) {
    return componentConfigSelectors.getPlotConfigs.apply(componentConfigSelectors, params)(state.componentConfig);
  };
};
var getGeneList = function getGeneList() {
  for (var _len7 = arguments.length, params = new Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
    params[_key7] = arguments[_key7];
  }
  return function (state) {
    return genesSelectors.getGeneList.apply(genesSelectors, params)(state.genes);
  };
};
var getFastqFiles = function getFastqFiles() {
  for (var _len8 = arguments.length, params = new Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
    params[_key8] = arguments[_key8];
  }
  return function (state) {
    return secondaryAnalysesSelectors.getFastqFiles.apply(secondaryAnalysesSelectors, params)(state.secondaryAnalyses);
  };
};
var getSampleLTFile = function getSampleLTFile() {
  for (var _len9 = arguments.length, params = new Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
    params[_key9] = arguments[_key9];
  }
  return function (state) {
    return secondaryAnalysesSelectors.getSampleLTFile.apply(secondaryAnalysesSelectors, params)(state.secondaryAnalyses);
  };
};
var getSelectedMetadataTracks = function getSelectedMetadataTracks() {
  for (var _len10 = arguments.length, params = new Array(_len10), _key10 = 0; _key10 < _len10; _key10++) {
    params[_key10] = arguments[_key10];
  }
  return function (state) {
    return componentConfigSelectors.getSelectedMetadataTracks.apply(componentConfigSelectors, params)(state);
  };
};
var getFilterChanges = function getFilterChanges() {
  for (var _len11 = arguments.length, params = new Array(_len11), _key11 = 0; _key11 < _len11; _key11++) {
    params[_key11] = arguments[_key11];
  }
  return function (state) {
    return experimentSettingsSelectors.getFilterChanges.apply(experimentSettingsSelectors, params)(state.experimentSettings);
  };
};
var getMetadataToSampleIds = function getMetadataToSampleIds() {
  for (var _len12 = arguments.length, params = new Array(_len12), _key12 = 0; _key12 < _len12; _key12++) {
    params[_key12] = arguments[_key12];
  }
  return function (state) {
    return samplesSelectors.getMetadataToSampleIds.apply(samplesSelectors, params)(state.samples);
  };
};
var getSamples = function getSamples() {
  for (var _len13 = arguments.length, params = new Array(_len13), _key13 = 0; _key13 < _len13; _key13++) {
    params[_key13] = arguments[_key13];
  }
  return function (state) {
    return samplesSelectors.getSamples.apply(samplesSelectors, params)(state.samples);
  };
};
export { getBackendStatus, getCellSets, getCellSetsHierarchy, getCellSetsHierarchyByType, getCellSetsHierarchyByKeys, getPlotConfigs, getGeneList, getFastqFiles, getSampleLTFile, getSelectedMetadataTracks, getFilterChanges, getMetadataToSampleIds, getSamples };