import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es7.array.includes.js";
import { updateProcessingSettingsFromQC, loadedProcessingConfig, updatePipelineVersion } from 'redux/actions/experimentSettings';
import { updateBackendStatus } from 'redux/actions/backendStatus';
import { replaceLoadedConfigs, updatePlotData } from 'redux/actions/componentConfig';
import { loadCellSets, updateCellSetsClustering } from 'redux/actions/cellSets';
import { loadSamples } from 'redux/actions/samples';
import { reloadExperimentInfo } from 'redux/actions/experiments';
import pushNotificationMessage from 'utils/pushNotificationMessage';
import { cellSetsUpdatedMessages } from 'utils/constants';
import endUserMessages from 'utils/endUserMessages';
var updateTypes = {
  QC: 'qc',
  GEM2S: 'gem2s',
  SEURAT: 'seurat',
  WORK_RESPONSE: 'WorkResponse',
  PLOT_CONFIG_REFRESH: 'PlotConfigRefresh'
};
var experimentUpdatesHandler = function experimentUpdatesHandler(dispatch) {
  return function (experimentId, update) {
    var _update$response;
    if (update.status) {
      dispatch(updateBackendStatus(experimentId, update.status));
    }
    if ((_update$response = update.response) !== null && _update$response !== void 0 && _update$response.error) {
      console.error('Experiment updates error:', update);

      // QC can handle updates even if there are errors
      if (update.type !== updateTypes.QC) return;
    }
    switch (update.type) {
      case updateTypes.QC:
        {
          return onQCUpdate(update, dispatch, experimentId);
        }
      case updateTypes.GEM2S:
        {
          return onGEM2SUpdate(update, dispatch, experimentId);
        }
      case updateTypes.SEURAT:
        {
          return onSeuratUpdate(update, dispatch, experimentId);
        }
      case updateTypes.WORK_RESPONSE:
        {
          return onWorkResponseUpdate(update, dispatch, experimentId);
        }
      case updateTypes.PLOT_CONFIG_REFRESH:
        {
          return onPlotConfigRefresh(update, dispatch);
        }
      default:
        {
          console.log("Error, unrecognized message type ".concat(update.type), update);
        }
    }
  };
};
var onQCUpdate = function onQCUpdate(update, dispatch, experimentId) {
  var input = update.input,
    output = update.output,
    pipelineVersion = update.pipelineVersion;
  dispatch(updatePipelineVersion(experimentId, pipelineVersion));

  // If there was an error and no output was generated, return
  if (!output) return;
  var processingConfigUpdate = output.config,
    plotData = output.plotData;
  if (processingConfigUpdate) {
    dispatch(updateProcessingSettingsFromQC(input.taskName, processingConfigUpdate, input.sampleUuid, false));
  }
  if (plotData) {
    Object.entries(plotData).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        plotUuid = _ref2[0],
        plotDataItem = _ref2[1];
      dispatch(updatePlotData(plotUuid, plotDataItem));
    });
  }

  // If the pipeline finished we have a new clustering, so fetch it
  if (update.status.pipeline.status === 'SUCCEEDED') {
    dispatch(loadCellSets(experimentId, true));
  }
};
var onGEM2SUpdate = function onGEM2SUpdate(update, dispatch, experimentId) {
  var _update$output$proces, _update$output, _update$output2;
  var processingConfig = (_update$output$proces = update === null || update === void 0 || (_update$output = update.output) === null || _update$output === void 0 ? void 0 : _update$output.processingConfig) !== null && _update$output$proces !== void 0 ? _update$output$proces : update === null || update === void 0 || (_update$output2 = update.output) === null || _update$output2 === void 0 || (_update$output2 = _update$output2.item) === null || _update$output2 === void 0 ? void 0 : _update$output2.processingConfig;
  if (processingConfig) {
    dispatch(loadedProcessingConfig(experimentId, processingConfig, true));
  }

  // If we finished subsetSeurat, then we now know which samples survived the subset
  // So load them
  if ((update === null || update === void 0 ? void 0 : update.taskName) === 'subsetSeurat') {
    dispatch(reloadExperimentInfo());
    dispatch(loadSamples(experimentId));
  }
};
var onWorkResponseUpdate = function onWorkResponseUpdate(update, dispatch, experimentId) {
  var workRequestName = update.request.body.name;
  if (['ClusterCells', 'ScTypeAnnotate'].includes(workRequestName)) {
    dispatch(updateCellSetsClustering(experimentId));
    pushNotificationMessage('success', cellSetsUpdatedMessages[workRequestName]);
  }
  if (workRequestName === 'GetExpressionCellSets') {
    dispatch(loadCellSets(experimentId, true));
    pushNotificationMessage('success', endUserMessages.SUCCESS_NEW_CLUSTER_CREATED);
  }
};
var onPlotConfigRefresh = function onPlotConfigRefresh(update, dispatch) {
  dispatch(replaceLoadedConfigs(update.updatedConfigs));
};
var onSeuratUpdate = function onSeuratUpdate(update, dispatch, experimentId) {
  // If the pipeline finished we have a new clustering, so fetch it
  if (update.status.seurat.status === 'SUCCEEDED') {
    dispatch(loadCellSets(experimentId, true));
  }
};
export default experimentUpdatesHandler;
export { updateTypes };